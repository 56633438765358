module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-c5114a901a/0/cache/gatsby-plugin-manifest-npm-5.12.0-7e9af7e841-6c8459995b.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5843c0b597010fc720b689a66b0ca2a9"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-gtag-virtual-f7b53a462b/0/cache/gatsby-plugin-gtag-npm-1.0.13-387068c71b-f3ac5be360.zip/node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-N6KW5CY5YH","head":false,"anonymize":true},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-3202655302/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
