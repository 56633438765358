// extracted by mini-css-extract-plugin
export var active = "header-module--active--6fa82";
export var apply_button = "header-module--apply_button--f4019";
export var flexible_space = "header-module--flexible_space--45a31";
export var header = "header-module--header--17909";
export var header_container = "header-module--header_container--f48e7";
export var heading_logo = "header-module--heading_logo--ac3e2";
export var logo = "header-module--logo--51567";
export var menu_button = "header-module--menu_button--c347c";
export var nav = "header-module--nav--9b26f";
export var twinkling = "header-module--twinkling--bc361";